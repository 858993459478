import React, { Fragment, useState, useEffect } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import * as moment  from 'moment';
import axios from 'axios';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
    Card,
    CardContent
  } from '@material-ui/core';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";


  const ChartSearchForm = React.memo(props => {
    const [periodValue, setPeriodValue] = useState('dm');
    const [sensorValue, setSensorValue] = useState('pvp');
    const [selectedDate, handleDateChange] = useState(new Date());
    const siteID = 'lt5';
    const chartInitialState = (siteID + '_' + 'pvp' + '_' + 'dm' + '_' + moment().format('YYYYMMDD'));
    const [chartFileName, setChartFileName] = useState([chartInitialState]);
    const [prevChartDate, setPrevChartDate] = useState('');
    const [nextChartDate, setNextChartDate] = useState('');
    const [userChart, setUserChart] = useState('');

    console.log('RENDERING CHARTSEARCH FORM');
    console.log(chartFileName);
    console.log(prevChartDate);
    console.log(nextChartDate);

    const handleSensorChange = (event) => {
      setSensorValue(event.target.value);
    };
  
    const handlePeriodChange = (event) => {
      setPeriodValue(event.target.value);
    };

    useEffect(() => {
      const getUserChart = async () => {
        const result = await axios(
          'https://lt5-api.earthlab33.com/api/chart/' +  chartFileName + '/',
        );
   
        setUserChart(result.data.chartfile);
        console.log(result.data.chartfile);
        props.pushUserChart(result.data.chartfile);
      };
      getUserChart();
      
    }, [chartFileName, props]);

    const submitHandler = (e) => {
      switch (periodValue) {
        case 'yd':
        console.log(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYY'));
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYY'));
        setPrevChartDate(moment(selectedDate).add(-1,"year").format('YYYY'));
        setNextChartDate(moment(selectedDate).add(1,"year").format('YYYY'));
        break;
        case 'md':
        console.log(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYYMM'));
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYYMM'));
        setPrevChartDate(moment(selectedDate).add(-1,"M").format('YYYYMM'));
        setNextChartDate(moment(selectedDate).add(1,"M").format('YYYYMM'));
        break;
        case 'wm':
        console.log(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYY_ww'));
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYY_ww'));
        setPrevChartDate(moment(selectedDate).subtract(1, 'week'));
        setNextChartDate(moment(selectedDate).add(1, 'week'));
        break;
        case 'dm':
        console.log(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYYMMDD'));
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(selectedDate).format('YYYYMMDD'));
        setPrevChartDate(moment(selectedDate).add(-1,"day").format('YYYYMMDD'));
        setNextChartDate(moment(selectedDate).add(1,"day").format('YYYYMMDD'));
        break;
        default:
        throw new Error('No Chart Found!');
      }
    };

    const prevChartHandler = (e) => {
      switch (periodValue) {
        case 'yd':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + prevChartDate);
        setPrevChartDate(moment(prevChartDate).add(-1,"year").format('YYYY'));
        setNextChartDate(moment(prevChartDate).add(1,"year").format('YYYY'));
        break;
        case 'md':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(prevChartDate).format('YYYYMM'));
        setPrevChartDate(moment(prevChartDate).add(-1,"M").format('YYYYMM'));
        setNextChartDate(moment(prevChartDate).add(1,"M").format('YYYYMM'));
        break;
        case 'wm':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(prevChartDate).format('YYYY_ww'));
        setPrevChartDate(moment(prevChartDate).subtract(1, 'week'));
        setNextChartDate(moment(prevChartDate).add(1, 'week'));
        break;
        case 'dm':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + prevChartDate);
        setPrevChartDate(moment(prevChartDate).add(-1,"day").format('YYYYMMDD'));
        setNextChartDate(moment(prevChartDate).add(1,"day").format('YYYYMMDD'));
        break;
        default:
        throw new Error('No Chart Found!');
      }
    };

    const nextChartHandler = (e) => {
      switch (periodValue) {
        case 'yd':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + nextChartDate);
        setPrevChartDate(moment(nextChartDate).add(-1,"year").format('YYYY'));
        setNextChartDate(moment(nextChartDate).add(1,"year").format('YYYY'));
        break;
        case 'md':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(nextChartDate).format('YYYYMM'));
        setPrevChartDate(moment(nextChartDate).add(-1,"M").format('YYYYMM'));
        setNextChartDate(moment(nextChartDate).add(1,"M").format('YYYYMM'));
        break;
        case 'wm':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + moment(nextChartDate).format('YYYY_ww'));
        setPrevChartDate(moment(nextChartDate).subtract(1, 'week'));
        setNextChartDate(moment(nextChartDate).add(1, 'week'));
        break;
        case 'dm':
        setChartFileName(siteID + '_' + sensorValue + '_' + periodValue + '_' + nextChartDate);
        setPrevChartDate(moment(nextChartDate).add(-1,"day").format('YYYYMMDD'));
        setNextChartDate(moment(nextChartDate).add(1,"day").format('YYYYMMDD'));
        break;
        default:
        throw new Error('No Chart Found!');
      }
    };

    return (
      <section className="chart-form">
        <Card>
            <CardContent>
                <form onSubmit={submitHandler}>
                <Grid container spacing={2}>
                 <Grid item xs={6}>
                 <Fragment>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      label="Select Date"
                      clearable
                      disableFuture
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                    <DatePicker
                      views={["year"]}
                      label="Year only"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                    </MuiPickersUtilsProvider>
                  </Fragment>
                 </Grid>
                 <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Sensor</FormLabel>
                    <RadioGroup row aria-label="sensor" name="sensor1" value={sensorValue} onChange={handleSensorChange}>
                      <FormControlLabel value="pvp" control={<Radio />} label="PVP" />
                      <FormControlLabel value="dht03" control={<Radio />} label="DHT03" />
                      <FormControlLabel value="dht04" control={<Radio />} label="DHT04" />
                      <FormControlLabel value="dht12" control={<Radio />} label="DHT12" />
                      <FormControlLabel value="dht16" control={<Radio />} label="DHT16" />
                      {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                    </RadioGroup>
                  </FormControl>
                 </Grid>
                 <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Period</FormLabel>
                    <RadioGroup row aria-label="period" name="perioddm" value={periodValue} onChange={handlePeriodChange}>
                      <FormControlLabel value="dm" control={<Radio />} label="Daily-Minutely" />
                      <FormControlLabel value="wm" control={<Radio />} label="Weekly-Minutely" />
                      <FormControlLabel value="md" control={<Radio />} label="Monthly-Daily" />
                      <FormControlLabel value="yd" control={<Radio />} label="Yearly-Daily" />
                      {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
                    </RadioGroup>
                  </FormControl>
                 </Grid>                   
                  <Grid item xs={12}>         
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={submitHandler}
                  >
                      SUBMIT
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={prevChartHandler}
                  >
                      PREVIOUS
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={nextChartHandler}
                  >
                      NEXT
                  </Button>
                  </Grid>  
                </Grid>  
                    {/* {props.loading && <LoadingIndicator />} */}
                </form>
          </CardContent>
        </Card>
      </section>
    );
  });
  
  export default ChartSearchForm;
